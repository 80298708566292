
.table-print tbody tr:nth-child(odd){
  background-color: rgb(223, 223, 223);
}

.table-print {
  background-color: white;
  text-align: center;
  margin-left: 5%;
  margin-bottom: 2%;
  font-size: 8px;
  width: 90%;
}

.table-print td, .table-print th {
  border: 1px solid black;
  padding: 4px;
}

.table-print thead {
  background-color: rgb(172, 172, 172);
}
