.color-d5dc3d { background-color: #d5dc3d; }
.color-d3818b { background-color: #d3818b; }
.color-ed4c28 { background-color: #ed4c28; }
.color-fee64b { background-color: #fee64b; }
.color-afd1da { background-color: #afd1da; }
.color-fdc98d { background-color: #fdc98d; }
.color-52e337 { background-color: #52e337; }
.color-ea3e2e { background-color: #ea3e2e; }
.color-f59bf4 { background-color: #f59bf4; }
.color-e2464a { background-color: #e2464a; }
.color-f4b57d { background-color: #f4b57d; }
.color-c697e1 { background-color: #c697e1; }
.color-defafb { background-color: #defafb; }
.color-dccaff { background-color: #dccaff; }
.color-f79cef { background-color: #f79cef; }
.color-beff9c { background-color: #beff9c; }
.color-f0f06f { background-color: #f0f06f; }
.color-8a68a5 { background-color: #8a68a5; }
.color-7bf31c { background-color: #7bf31c; }
.color-ed84c0 { background-color: #ed84c0; }
.color-ec1fe8 { background-color: #ec1fe8; }
.color-f790b1 { background-color: #f790b1; }
.color-fae500 { background-color: #fae500; }
.color-e8dc29 { background-color: #e8dc29; }
.color-fafa67 { background-color: #fafa67; }
.color-8eef8d { background-color: #8eef8d; }
.color-f47601 { background-color: #f47601; }
.color-f3cf2c { background-color: #f3cf2c; }
.color-29e583 { background-color: #29e583; }
.color-c9c3ef { background-color: #c9c3ef; }
.color-b6c523 { background-color: #b6c523; }
.color-259e6f { background-color: #259e6f; }
.color-89e072 { background-color: #89e072; }
.color-94cb03 { background-color: #94cb03; }
.color-e99655 { background-color: #e99655; }
.color-f5d797 { background-color: #f5d797; }
.color-f2e7bf { background-color: #f2e7bf; }
.color-d6e2d2 { background-color: #d6e2d2; }
.color-128c5a { background-color: #128c5a; }
.color-ffb7fc { background-color: #ffb7fc; }
.color-fd9205 { background-color: #fd9205; }
.color-ff8847 { background-color: #ff8847; }
.color-f3fc92 { background-color: #f3fc92; }
.color-5ce75b { background-color: #5ce75b; }
.color-f9f23b { background-color: #f9f23b; }
.color-825af5 { background-color: #825af5; }
.color-37b44e { background-color: #37b44e; }
.color-a1d4ea { background-color: #a1d4ea; }
.color-46eb06 { background-color: #46eb06; }
.color-cd6c05 { background-color: #cd6c05; }
.color-85d99e { background-color: #85d99e; }
.color-81ee73 { background-color: #81ee73; }
.color-41d4a4 { background-color: #41d4a4; }
