.steps-content-tables {
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  text-align: center;
}

.steps-action {
  margin-top: 24px;
}
